import { MapViewPins } from "./map/MapViewPins";
import { MapViewTour } from "./map/MapViewTour";
import { MapHighlight } from "./map/MapHighlight";

import { MapPopupManager } from "./map/MapPopupManager";
import { getJSONData } from "./helpers/utils";

// xxx import { MapViewUnclustered } from "./MapViewUnclustered";
// import { MapClusterDetail } from "./MapClusterDetail";
// import { MapUserLocation } from "./MapUserLocation";
// import { MapHighlights, MapHighlightType } from "./MapHighlights";
// import { MapTour, MapTourType } from "./MapTour";

const { $ } = window;

export class MapController {
  POPUP_OFFSET_MOUSE = [0, -25];

  POPUP_OFFSET_TOUCH = [53, 27];

  MAX_BOUNDS_ZOOM = 14;

  DRAWER_MAX_WIDTH = 740;

  win = $(window);

  isInit;

  isAnimating = false;

  isLoaded = false;

  isStyleLoaded = false;

  isBaseDataLoaded = false;

  isReady = false;

  hasDrawer = false;

  isDrawerOpen = false;

  isDrawerInit = false;

  isDrawerClick = false;

  id;

  map;

  container;

  popups;

  highlight;

  activePinId;

  views = {};

  currentView = "";

  geoJsonAllData;

  // xxx clean up
  // clickBlock = false;
  // highlights;
  // // tour: MapTour;
  // intitiallyFitToBounds;

  onLoadJobs = [];

  constructor() {
    this.isInit = null;
    this.map = null;
    this.activePinId = 0;

    this.popups = new MapPopupManager(this);
    this.highlight = new MapHighlight(this);

    this.views.pins = new MapViewPins(this);
    this.views.tour = new MapViewTour(this);
  }

  openDrawer() {
    const self = this;
    if (!self.isInit || !self.container) return;

    self.isDrawerOpen = true;
    self.container.data("drawer-open", 1);
    window.app.classContainer.data("drawer-open", 1);

    window.app.classContainer.find(".page-content > div").off("click");
  }

  closeDrawer(doNotAttachEvent) {
    const self = this;
    if (!self.isInit || !self.container) return;

    self.container.data("drawer-open", 0);
    window.app.classContainer.data("drawer-open", 0);
    self.isDrawerOpen = false;

    if (doNotAttachEvent) return;

    window.app.classContainer
      .find(".page-content div")
      .first()
      .one("click", (e) => {
        e.preventDefault();
        self.openDrawer();
      });
  }

  toggleDrawer() {
    const self = this;
    if (!self.isInit || !self.container) return;

    if (self.isDrawerOpen) {
      self.closeDrawer();
    } else {
      self.openDrawer();
    }
  }

  onResize() {
    const self = this;

    if (!self.isInit || !self.container) return;

    if (self.currentView === "tour") {
      self.views.tour.onResize();
    } else if (self.currentView === "resize") {
      self.views.resize.onResize();
    }

    const observeDrawer = () => {
      if (!self.isDrawerInit) return;

      if (window.app.wW < self.DRAWER_MAX_WIDTH) {
        if (self.isDrawerOpen) {
          self.isDrawerClick = true;
          self.closeDrawer();

          setTimeout(() => {
            self.isDrawerClick = false;
          }, 100);
        }
      } else {
        self.isDrawerOpen = true;
      }
    };

    if (
      window.app.wW >= this.DRAWER_MAX_WIDTH ||
      (!self.hasDrawer && self.isDrawerInit)
    ) {
      if (self.isDrawerInit) {
        self.map.off("dragstart", observeDrawer);
        self.map.off("mousedown", observeDrawer);
        self.map.off("touchstart", observeDrawer);
        self.isDrawerInit = false;
      }

      self.openDrawer();
    } else if (self.hasDrawer && !self.isDrawerInit) {
      self.isDrawerInit = true;
      self.openDrawer();
      self.map.on("dragstart", observeDrawer);
      self.map.on("mousedown", observeDrawer);
      self.map.on("touchstart", observeDrawer);
    }
  }

  onInit(id) {
    const self = this;

    if (self.isInit) return;

    self.id = id;

    if (!$(".map-container").exists()) return;

    self.container = $(".map-container");

    window.mapboxgl.accessToken = window.WSM.map.accessToken;
    self.map = new window.mapboxgl.Map({
      container: self.id,
      style: window.WSM.map.styleJsonUrl,
      center: window.WSM.map.center,
      zoom: window.WSM.map.zoom,
      minZoom: window.WSM.map.minZoom,
      maxZoom: window.WSM.map.maxZoom,
      attributionControl: false,
      maxBounds: window.WSM.map.maxBounds,
    });

    self.ready = false;
    self.styleLoaded = false;
    self.loaded = false;
    self.onLoadJobs = [];

    const process = async () => {
      if (self.ready) return;
      self.ready = true;

      self.onLoadJobs.forEach(async (f) => {
        await new Promise(f);
      });
    };

    self.container
      .find(`button[data-type="zoom-in"]`)
      .off("click")
      .on("click", () => {
        if (self.ready) {
          self.map.zoomIn({ duration: 1000 });
        }
      });

    self.container
      .find(`button[data-type="zoom-out"]`)
      .off("click")
      .on("click", () => {
        if (self.ready) {
          self.map.zoomOut({ duration: 1000 });
        }
      });

    self.container
      .find(`button[data-type="north"]`)
      .off("click")
      .on("click", () => {
        if (self.ready) {
          self.map.resetNorth({ duration: 1000 });
        }
      });

    self.container
      .find(`button[data-type="close-drawer"]`)
      .off("click")
      .on("click", () => {
        if (self.isDrawerInit) {
          self.toggleDrawer();
        }
      });

    self.container
      .find(`button[data-type="filter"]`)
      .off("click")
      .on("click", () => {
        self.container.data(
          "filter-visible",
          self.container.data("filter-visible") === 1 ? 0 : 1
        );
      });

    const maybeProcess = async () => {
      if (
        ((self.container.data("geojson") === 1 && self.baseDataLoaded) ||
          self.container.data("geojson") !== 1) &&
        self.styleLoaded &&
        self.loaded
      ) {
        await process();
      }
    };

    self.map.once("style.load", () => {
      self.styleLoaded = true;
      maybeProcess();
    });
    self.map.once("load", () => {
      self.loaded = true;

      self.onResize();
      maybeProcess();
    });

    if (self.container.data("geojson") === 1) {
      fetch(
        `/wp-content/plugins/map/fast-query-geojson.php?scope=sw_${
          window.WSM.blogId > 1 ? `${window.WSM.blogId}_` : ""
        }`
      ).then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          if (
            data &&
            data?.type &&
            data?.type === "FeatureCollection" &&
            Array.isArray(data?.features)
          ) {
            self.geoJsonAllData = data;
            self.baseDataLoaded = true;

            maybeProcess();
          }
        }
      });
    }

    self.isInit = true;

    self.onResize();

    self.win.trigger("map:init");
  }

  loadUrl(url) {
    const self = this;

    if (self.isDrawerClick) return;

    window.app.pjax.loadUrl(url);
  }

  onScroll() {
    const self = this;
    if (!self.isInit) return;

    if (self.currentView === "tour") {
      self.views.tour.onScroll();
    }
  }

  onReady() {
    const self = this;
    if (!self.isInit) return;

    self.container.find('[data-type="close-drawer"]').attr("tabindex", -1);

    self.container
      .find('[data-type="back"]')
      .off("click")
      .on("click", () => {
        if (
          window?.app?.url_referrer &&
          window.app.url_referrer.indexOf(window.WSM.home_url) !== -1
        ) {
          window.history.back();
        } else {
          window.app.pjax.loadUrl(self.container.data("back-url"));
        }
      });

    const cards = window.app.classContainer.find(".cards");

    self.container.attr(
      "data-category",
      cards.exists() && cards.data("category") ? cards.data("category") : "all"
    );

    self.container
      .find(".filter-options a")
      .off("click")
      .on("click", (e) => {
        const link = $(e.target);

        if (link.data("cat") === self.container.attr("data-category")) {
          e.preventDefault();
          self.loadUrl(self.container.data("back-url"));
        }
      });

    if (
      window.app.classContainer.hasClass("hide-map-ui") ||
      window.app.classContainer.find(".block.map").exists()
    ) {
      self.container.data("show-ui", 0);
    } else {
      self.container.data("show-ui", 1);
    }

    if (window.app.classContainer.hasClass("has-full-content")) {
      self.container.data("full-content", 1);
    } else {
      self.container.data("full-content", 0);
    }

    if (window.app.classContainer.hasClass("has-back")) {
      self.container.data("back", 1);
    } else {
      self.container.data("back", 0);
    }

    self.isDrawerOpen = true;
    self.container.data("drawer-open", 1);
    window.app.classContainer.data("drawer-open", 1);

    if (window.app.classContainer.hasClass("has-drawer")) {
      self.hasDrawer = true;
      self.container.data("drawer", 1);
    } else {
      self.hasDrawer = false;
      self.container.data("drawer", 0);
    }

    setTimeout(() => {
      self.container.data("transition-block", 0);
    }, 100);

    const mapBlock = window.app.classContainer.find(".block.map");

    if (self.container.data("geojson") === 1) {
      if (window.app.classContainer.hasClass("map-listing")) {
        if (self.currentView !== "pins") {
          self.setView("pins");
        }

        if (self.container.data("category") !== "all") {
          self.setFilter("pins", [
            "==",
            ["get", "category"],
            self.container.data("category"),
          ]);
        } else {
          self.setFilter("pins", null);
        }

        self.runTask(() => {
          self.views.pins.onReady();
        });
      } else if (mapBlock.exists() && mapBlock.hasClass("explore")) {
        const resetMap = () => {
          self.map.easeTo({
            center: window.WSM.map.center,
            zoom: window.WSM.map.zoom,
            duration: 1250,
          });
        };
        if (self.currentView !== "pins") {
          self.setView("pins", resetMap);
        } else {
          self.runTask(resetMap);
        }

        self.setFilter("pins", null);
      } else if (
        !window.app.classContainer.hasClass("single-tour") &&
        !mapBlock.exists()
      ) {
        if (self.currentView !== "pins") {
          self.setView("pins");
        }
        self.runTask(() => {
          self.setFilter("pins", null);
        });

        if (window.app.classContainer.hasClass("single-pin")) {
          self.runTask(() => {
            const row = window.app.classContainer.find(
              ".row.color-default.data"
            );
            if (row) {
              self.highlight.pin(
                row.data("id"),
                row.data("pin-cat"),
                row.data("pin-type"),
                row.data("lng"),
                row.data("lat"),
                {
                  panTo: true,
                }
              );
            }
          });
        }
      }
    }

    if (mapBlock.exists() && mapBlock.hasClass("pin")) {
      const json = getJSONData(`${mapBlock.attr("id")}-json`);

      if (
        json?.features?.length &&
        json?.features?.[0]?.geometry?.coordinates
      ) {
        const resetMap = () => {
          let offset = [25, 0];

          if (window.app.wW > 740) {
            const headerHeight = window.app.wW > 998 ? 35 : 30;
            const oT =
              window.app.wH > window.app.wW * 0.666
                ? headerHeight
                : headerHeight +
                  (0.5 *
                    (window.app.wH - headerHeight - window.app.wW * 0.66)) /
                    2;
            offset = [oT, 0];
          }

          self.highlight.bigPin(
            mapBlock.attr("id"),
            json?.features[0].properties.category,
            json?.features[0].properties.type,
            json?.features?.[0]?.geometry?.coordinates[0],
            json?.features?.[0]?.geometry?.coordinates[1],
            {
              offset,
              jumpTo: true,
            }
          );
        };

        if (self.currentView !== "pins") {
          self.setView("pins", resetMap);
        } else {
          self.runTask(resetMap);
        }

        self.setFilter("pins", null);
      }
    }

    if (
      window.app.classContainer.hasClass("single-tour") ||
      (mapBlock.exists() && mapBlock.hasClass("area"))
    ) {
      if (self.currentView !== "tour") {
        self.setView("tour");
      }
    }

    this.win.trigger("map:ready");

    self.runTask(self.onScroll);
  }

  onPjaxSend() {
    const self = this;
    self.hasDrawer = true;

    self.popups.hideAll();

    if (self.container) {
      self.container.data("transition-block", 1);
    }
    self.onResize();
  }

  getBounds() {
    return new window.mapboxgl.LngLatBounds(
      window.WSM.map.maxBounds[0],
      window.WSM.map.maxBounds[1]
    );
  }

  inBounds = (coordinates) => {
    if (
      coordinates[0] < window.WSM.map.maxBounds[0][0] ||
      coordinates[0] > window.WSM.map.maxBounds[1][0]
    )
      return false;
    if (
      coordinates[1] < window.WSM.map.maxBounds[0][1] ||
      coordinates[1] > window.WSM.map.maxBounds[1][1]
    )
      return false;
    return true;
  };

  // fitToBounds = (bounds, options = {}) => {
  //   if (!this.map) return;

  //   let calculatedOptions = this.map.cameraForBounds(bounds, options);

  //   if (!calculatedOptions) return;

  //   calculatedOptions = {
  //     ...calculatedOptions,
  //     ...options,
  //   };

  //   calculatedOptions.zoom = Math.max(
  //     calculatedOptions.zoom,
  //     options?.minZoom ?? this.config.boundingBoxZoom
  //   );

  //   // Explictly remove the padding field because, calculatedOptions already accounts for padding by setting zoom and center accordingly.
  //   if (calculatedOptions?.padding) delete calculatedOptions.padding;

  //   if (calculatedOptions?.minZoom) delete calculatedOptions.minZoom;

  //   this.map.easeTo(options);
  // };

  // clearOnloadJobs = () => {
  //   this.onLoadJobs = [];
  // };

  // setInitallyFitToBounds = (flag) => {
  //   this.intitiallyFitToBounds = flag;
  // };

  runTask(task) {
    const self = this;
    if (self.map && typeof task === "function") {
      const run = async (resolve) => {
        task.call(self);
        if (typeof resolve !== "undefined") resolve(true);
      };

      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  setView(view, onShownCallback) {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        Object.keys(self.views).forEach((v) => {
          if (v !== view) {
            self.views[v].clear();
          }
        });
        self.popups.hideAll();
        self.views[view].setData();

        setTimeout(() => {
          self.views[view].render();
          self.views[view].show();

          if (typeof onShownCallback === "function") onShownCallback.call();

          if (typeof resolve !== "undefined") resolve(true);
        }, 100);

        self.currentView = view;
      };

      if (view === self.currentView && self.views[view]?.isDataSet) return;

      if (!self.ready) {
        self.currentView = view;
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  hideCurrentView() {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        self.popups.hideAll();
        self.views[self.currentView].hide();
        if (typeof resolve !== "undefined") resolve(true);
      };
      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  showCurrentView() {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        self.popups.hideAll();
        self.views[self.currentView].show();

        if (typeof resolve !== "undefined") resolve(true);
      };
      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  renderCurrentView() {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        self.popups.hideAll();
        self.views[self.currentView].render();
        if (typeof resolve !== "undefined") resolve(true);
      };
      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  setCurrentViewData(data, show) {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        self.popups.hideAll();
        self.clusterDetail.hide();
        self.views[self.currentView].setData(data);

        if (show) {
          setTimeout(() => {
            self.views[self.currentView].show();
            if (typeof resolve !== "undefined") resolve(true);
          }, 100);
        }
      };

      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  setFilter(view, filter) {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        if (typeof self?.views[view]?.setFilter === "function") {
          self?.views[view]?.setFilter(filter);
        }
        if (typeof resolve !== "undefined") resolve(true);
      };

      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  // fitToCurrentViewBounds() {
  //   const self = this;
  //   if (self.map) {
  //     const run = async (resolve) => {
  //       self.popups.hideAll();
  //       self.views[self.currentView].fitToBounds();
  //       if (typeof resolve !== "undefined") resolve(true);
  //     };

  //     if (!self.ready) {
  //       self.onLoadJobs.push(run);
  //     } else {
  //       run();
  //     }
  //   }
  // }

  // xxx make the following better
  getCenterOffset() {
    if (typeof window === "undefined") return [0, 0];

    if (window.app.wW < 740) {
      return [window.app.wW / 2 - 50, 25];
    }

    return [442 / 2, 40];
  }

  // getBoundsPadding() {
  //   if (typeof window === "undefined")
  //     return {
  //       top: 0,
  //       right: 0,
  //       bottom: 0,
  //       left: 0,
  //     };

  //   const isMobile = window.matchMedia("(max-width: 44.9999em)").matches;
  //   const isTablet = window.matchMedia(
  //     "(min-width: 45em) and (max-width: 74.9999em)"
  //   ).matches;
  //   const isTabletWide = window.matchMedia(
  //     "(min-width: 62em) and (max-width: 74.9999em)"
  //   ).matches;
  //   const isDesktop = window.matchMedia(
  //     "(min-width: 75em) and (max-width: 119.9999em)"
  //   ).matches;

  //   if (isMobile) {
  //     return {
  //       top: 80,
  //       right: 40,
  //       bottom: 100,
  //       left: 80,
  //     };
  //   }
  //   if (isTablet && !isTabletWide) {
  //     return {
  //       top: 80,
  //       right: 40,
  //       bottom: 40,
  //       left: 120,
  //     };
  //   }
  //   if (isTabletWide) {
  //     return {
  //       top: 80,
  //       right: 40,
  //       bottom: 40,
  //       left: 160,
  //     };
  //   }
  //   if (isDesktop) {
  //     return {
  //       top: 80,
  //       right: 40,
  //       bottom: 100,
  //       left: 400,
  //     };
  //   }
  //   return {
  //     top: 80,
  //     right: 40,
  //     bottom: 100,
  //     left: 695 + (window.innerWidth * 0.08 - 55) + 40,
  //   };
  // }

  panTo(lng, lat, options) {
    const self = this;
    if (self.map) {
      const run = async (resolve) => {
        if (Number.isNaN(lng) || Number.isNaN(lat)) return;
        self.map?.stop();
        self.map?.setPadding({
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        });
        self.map?.panTo(
          [lng, lat],
          {
            animate: options?.animate ?? true,
            duration: options?.duration ?? 1250,
            essential: true,
            offset: options?.offset ? options.offset : self.getCenterOffset(),
          },
          {
            customAnimation: true,
          }
        );
        if (typeof resolve !== "undefined") resolve(true);
      };

      if (!self.ready) {
        self.onLoadJobs.push(run);
      } else {
        run();
      }
    }
  }

  jumpTo(lng, lat, options) {
    this.panTo(lng, lat, {
      animate: false,
      duration: 0,
      ...options,
    });
  }

  toggleLayersVisibility = (layers, visibility) => {
    const self = this;
    if (!self.map) return;

    layers.forEach((lId) => {
      if (this?.map?.getLayer(lId)) {
        this?.map?.setLayoutProperty(lId, "visibility", visibility);
      }
    });
  };

  removeLayers = (layers) => {
    const self = this;
    if (!self.map) return;

    layers.forEach((lId) => {
      if (this?.map?.getLayer(lId)) {
        self.map?.removeLayer(lId);
      }
    });
  };
}

window.mapController = new MapController();
