import { MapPopup } from "./MapPopup";

const { $ } = window;

const POPUP_PIN_OFFSET = [0, 0];
const POPUP_TIMINGS = {
  fadeIn: 1020,
  fadeOut: 1060,
};

export class MapHighlight {
  controller;

  constructor(mapController) {
    this.controller = mapController;
  }

  addPin(id, lng, lat, content, options) {
    const self = this;

    const popupId = `highlight-${id}`;

    self.controller.popups.hideAllInScopeButKeepId("highlight-", popupId);

    if (self.controller.popups.exists(popupId)) {
      self.controller.popups.show(popupId);
    } else {
      const popupContent = $(content);
      const popup = new MapPopup(
        popupId,
        self.controller,
        [lng, lat],
        popupContent,
        {
          offset: POPUP_PIN_OFFSET,
          timings: POPUP_TIMINGS,
          onClick: options?.onClick,
        }
      );
      self.controller.popups.add(popupId, popup);

      if (options?.panTo)
        self.controller.panTo(lng, lat, {
          offset: options?.offset,
        });

      if (options?.jumpTo)
        self.controller.jumpTo(lng, lat, {
          offset: options?.offset,
        });
    }
  }

  pin(id, cat, type, lng, lat, options) {
    const self = this;
    self.addPin(
      id,
      lng,
      lat,
      `<div class="popup pin pin-icon ${cat}" style="--ibs:1"><div class="icon ${type}"></div></div>`,
      options
    );
  }

  bigPin(id, cat, type, lng, lat, options) {
    const self = this;
    self.addPin(
      id,
      lng,
      lat,
      `<div class="popup pin big pin-icon ${cat}" style="--ibs:1"><div class="icon ${type}"></div></div>`,
      options
    );
  }

  tourStop(id, number, lng, lat, onClick) {
    const self = this;
    self.addPin(
      id,
      lng,
      lat,
      `<div class="popup pin pin-icon tours" style="--ibs:1"><div class="number">${number}</div></div>`,
      { onClick, panTo: true }
    );
  }
}
