import { primaryInput } from "detect-it";

import { MapPopup } from "./MapPopup";
import { EMPTY_GEOJSON } from "./utils";

const { $ } = window;

const POPUP_LABEL_OFFSET = [0, -32];

export class MapViewPins {
  controller;

  bounds;

  events = {};

  isVisible = false;

  hasDataSet = false;

  layers = ["pins"];

  currentFilter = null;

  constructor(mapController) {
    this.controller = mapController;
    this.bounds = mapController.getBounds();
  }

  setData(data) {
    const self = this;
    if (self.controller?.map) {
      if (!self.controller?.map) return;

      self.hide();

      if (!self.controller.map.getSource("source-pins")) {
        self.controller.map.addSource("source-pins", {
          type: "geojson",
          data: data ?? self.controller.geoJsonAllData ?? EMPTY_GEOJSON,
          promoteId: true,
        });
      } else {
        self.controller?.map
          ?.getSource("source-pins")
          ?.setData(data ?? self.controller.geoJsonAllData ?? EMPTY_GEOJSON);
      }

      let bounds;

      if (
        (data ?? self.controller.geoJsonAllData ?? EMPTY_GEOJSON)?.features
          ?.length
      ) {
        for (
          let i = 0;
          i <
          (data ?? self.controller.geoJsonAllData ?? EMPTY_GEOJSON)?.features
            ?.length;
          i += 1
        ) {
          const coordinates =
            (data ?? self.controller.geoJsonAllData ?? EMPTY_GEOJSON)?.features[
              i
            ]?.geometry?.coordinates ?? window.WSM.map.center;

          if (self.controller.inBounds(coordinates)) {
            if (!bounds) {
              bounds = new window.mapboxgl.LngLatBounds(
                coordinates,
                coordinates
              );
            } else {
              bounds.extend(coordinates);
            }
          } else {
            const feature = (
              data ??
              self.controller.geoJsonAllData ??
              EMPTY_GEOJSON
            )?.features[i];

            // eslint-disable-next-line no-console
            console.warn(
              `Skipped location as it is out of bounds: ID(${feature.properties.id}) - ${feature.properties.title}`
            );
          }
        }
      }

      if (bounds) {
        self.bounds = bounds;
      } else {
        self.bounds = self.controller.getBounds();
      }
      self.hasDataSet = true;
    }
  }

  render() {
    const self = this;
    if (self.controller?.map) {
      if (
        !self.controller?.map ||
        !self.controller.map.getSource("source-pins")
      )
        return;

      self.clear(true);

      // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#symbol
      self.controller.map.addLayer({
        id: "pins",
        type: "symbol",
        source: "source-pins",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": "{marker-symbol}",
          "icon-size": 0.5,
          "icon-anchor": "bottom",
        },
        // paint: {
        //   // //   "circle-radius": 8,
        //   // //   "circle-stroke-width": 2,
        //   // //   "circle-color": "red",
        //   // //   "circle-stroke-color": "white",
        //   "text-color": "#333",
        // },

        ...(self.currentFilter ? { filter: self.currentFilter } : {}),
      });

      const showMapPop = (e) => {
        const feature = e?.features?.[0];
        if (!feature) return;
        const coordinates = feature.geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        try {
          const id = `pin-${feature?.properties?.id}`;

          self.controller.popups.hideAllInScopeButKeepId("pin-", id);

          if (self.controller.popups.exists(id)) {
            self.controller.popups.show(id);
          } else {
            const content = $(
              `<div class="popup label">${feature?.properties?.title}</div>`
            );
            const popup = new MapPopup(
              id,
              self.controller,
              coordinates,
              content,
              { offset: POPUP_LABEL_OFFSET, minZoom: 12 }
            );
            self.controller.popups.add(id, popup);
          }
        } catch (err) {
          // do nothing ...
        }
      };

      if (primaryInput !== "touch") {
        self.events["mouseenter-pins"] = (e) => {
          if (self.controller.isAnimating) return;
          if (self.controller.map) {
            // Change the cursor style as a UI indicator.
            self.controller.map.getCanvas().style.cursor = "pointer";

            if (e?.features?.[0]) showMapPop(e);
          }
        };

        self.controller.map.on(
          "mouseenter",
          "pins",
          self.events["mouseenter-pins"]
        );

        self.events["mousemove-pins"] = (e) => {
          if (self.controller.isAnimating) return;
          if (self.controller.map) {
            // Change the cursor style as a UI indicator.
            self.controller.map.getCanvas().style.cursor = "pointer";

            if (e?.features?.[0]) showMapPop(e);
          }
        };
        self.controller.map.on(
          "mousemove",
          "pins",
          self.events["mousemove-pins"]
        );

        self.events["mouseleave-pins"] = () => {
          if (self.controller.map) {
            self.controller.map.getCanvas().style.cursor = "";

            self.controller.popups.hideAllInScope("pin-");
          }
        };

        self.controller.map.on(
          "mouseleave",
          "pins",
          self.events["mouseleave-pins"]
        );
      }

      self.events["click-pins"] = (e) => {
        self.controller.loadUrl(`/${e.features?.[0]?.properties?.slug}`);
      };

      self.controller.map.on("click", "pins", self.events["click-pins"]);

      self.show();
    }
  }

  onReady() {
    const self = this;

    if (primaryInput !== "touch") {
      $(".card.observe")
        .off("pointerenter")
        .on("pointerenter", (e) => {
          const card = $(e.target);
          self.controller.highlight.pin(
            card.data("id"),
            card.data("pin-cat"),
            card.data("pin-type"),
            card.data("lng"),
            card.data("lat")
          );
        });
      $(".card.observe")
        .off("pointerleave")
        .on("pointerleave", () => {
          self.controller.popups.hideAllInScope("highlight-");
        });
    }
  }

  setFilter(filter) {
    const self = this;
    if (self.controller?.map) {
      self.currentFilter = filter;
      if (self.controller.map?.getLayer("pins")) {
        self.controller.map.setFilter("pins", self.currentFilter);
      }
    }
  }

  fitToBounds() {
    const self = this;
    if (self.controller?.map) {
      self.controller.fitToBounds(self.bounds, {
        maxZoom: self.controller.MAX_BOUNDS_ZOOM,
        padding: self.controller.getBoundsPadding(),
      });
    }
  }

  hide() {
    const self = this;
    if (!self.isVisible) return;

    self.controller.toggleLayersVisibility(self.layers, "none");

    self.isVisible = false;
  }

  show() {
    const self = this;
    if (self.isVisible) return;

    self.controller.toggleLayersVisibility(self.layers, "visible");

    self.isVisible = true;
  }

  clear(keepFilter) {
    const self = this;
    if (self.controller?.map) {
      self.isVisible = false;
      self.hasDataSet = true;

      if (!keepFilter) self.currentFilter = null;

      self.controller.removeLayers(self.layers);

      if (Object.keys(self.events).length) {
        Object.keys(self.events).forEach((key) => {
          if (self.controller?.map) {
            const params = key.split("-");
            if (params.length > 1) {
              self.controller.map.off(params[0], params[1], self.events[key]);
            } else {
              self.controller.map.off(key, self.events[key]);
            }
          }
        });
        self.events = {};
      }
    }
  }
}
