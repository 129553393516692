import $ from "cash-dom";

export const onImageLoaded = (img, callback) => {
  img = $(img);
  img = !img.is("img") ? img.find("img").first() : img;

  if (img.exists()) {
    img = img.get(0);

    if (
      img.complete &&
      (img.naturalWidth || img.src.indexOf("svg") !== false)
    ) {
      callback.call();
    } else {
      img.onload = callback;
      img.onerror = callback;
    }
  }
};

export const getJSONData = (key) => {
  try {
    return JSON.parse($(`script[type="application/json"]#${key}`).text());
  } catch (err) {
    // if we have not valid json or dont have it
    return null;
  }
};

export const getScript = (url, success) => {
  const body = document.querySelector("body");
  if (body) {
    const script = document.createElement("script");

    script.async = true;
    script.src = url;

    if (success && typeof success === "function") {
      script.onload = success;
    }

    body.appendChild(script);
  }
};
