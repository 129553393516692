export class MapPopupManager {
  popups = {};

  controller = null;

  constructor(mapController) {
    const self = this;
    this.controller = mapController;

    window.app.win.off("map:popup:remove").on("map:popup:remove", (_e, d) => {
      if (Array.isArray(d) && d.length) self.remove(d[0]);
    });
  }

  add(id, popup) {
    const self = this;
    self.popups[id] = popup;
    popup.show();
  }

  remove(id) {
    const self = this;

    if (id in self.popups) {
      self.popups[id].remove();
      delete self.popups[id];
    }
  }

  exists(id) {
    const self = this;

    return id in self.popups;
  }

  show(id) {
    const self = this;
    if (id in self.popups) {
      self.popups[id].show();
    }
  }

  hide(id) {
    const self = this;
    if (id in self.popups) {
      self.popups[id].hide();
    }
  }

  hideAll() {
    const self = this;

    Object.keys(self.popups).forEach((key) => {
      self.popups[key].hide();
    });
  }

  hideAllInScope(scopePrefix) {
    const self = this;

    Object.keys(self.popups).forEach((key) => {
      if (key.indexOf(scopePrefix) > -1) {
        self.popups[key].hide();
      }
    });
  }

  hideAllInScopeButKeepId(scopePrefix, id) {
    const self = this;

    Object.keys(self.popups).forEach((key) => {
      if (key !== id && key.indexOf(scopePrefix) > -1) {
        self.popups[key].hide();
      }
    });
  }

  hideAllInScopeButKeepIds(scopePrefix, arrIds) {
    const self = this;

    Object.keys(self.popups).forEach((key) => {
      if (!arrIds.includes(key) && key.indexOf(scopePrefix) > -1) {
        self.popups[key].hide();
      }
    });
  }
}
