const TIMING_FADE_IN = 150;
const TIMING_FADE_OUT = 200;

export class MapPopup {
  id;

  options = null;

  content = null;

  timeout1 = null;

  timeout2 = null;

  isFadingIn = false;

  isFadingOut = false;

  controller;

  timingFadeOut;

  timingFadeIn;

  constructor(id, mapController, coordinates, content, options) {
    this.id = id;

    this.controller = mapController;

    this.options = options;

    this.timingFadeIn = options?.timings?.fadeIn ?? TIMING_FADE_IN;
    this.timingFadeOut = options?.timings?.fadeOut ?? TIMING_FADE_OUT;

    this.popup = new window.mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    if (typeof options?.onClick !== "undefined") {
      content.on("click", options.onClick);
      content.addClass("has-on-click");
    }
    this.content = content.addClass("popup");

    this.popup
      .setLngLat(coordinates)
      .setDOMContent(content.get(0))
      .addTo(this.controller.map);

    if (options?.offset) {
      this.popup.setOffset(options.offset);
    }
  }

  show() {
    const self = this;
    if (!self?.controller?.map || self.isFadingIn) return;

    if (
      self.options?.minZoom &&
      self.options?.minZoom > self.controller.map.getZoom()
    )
      return;

    clearTimeout(self.timeout1);
    clearTimeout(self.timeout2);

    self.isFadingIn = true;
    self.isFadingOut = false;

    self.timeout1 = setTimeout(() => {
      self.content.addClass("fade-in");
    }, 20);

    self.timeout2 = setTimeout(() => {
      self.isFadingIn = false;
      self.content.removeClass("fade-in").addClass("faded-in");
    }, TIMING_FADE_IN);
  }

  remove() {
    const self = this;
    self.popup.remove();
  }

  hide() {
    const self = this;
    if (!this?.controller?.map || self.isFadingOut) return;

    clearTimeout(self.timeout1);
    clearTimeout(self.timeout2);

    self.isFadingIn = false;
    self.isFadingOut = true;

    self.timeout1 = setTimeout(() => {
      self.content.removeClass("faded-in").addClass("fade-out");
    }, 60);
    self.timeout2 = setTimeout(() => {
      self.isFadingOut = false;
      window.app.win.trigger("map:popup:remove", [self.id]);
    }, TIMING_FADE_OUT);
  }
}
