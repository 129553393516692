import { primaryInput } from "detect-it";
import throttle from "lodash/throttle";

import { getJSONData } from "../helpers/utils";
import { EMPTY_GEOJSON } from "./utils";

const { $ } = window;

const AREA_COLOR = "rgb(255, 224, 216)";
const AREA_HOVER_COLOR = "rgb(116, 145, 194)";

export class MapViewTour {
  controller;

  bounds;

  events = {};

  isVisible = false;

  hasDataSet = false;

  sourceNameAreas = "source-areas";

  sourceNamePins = "source-tour";

  type = "pin";

  layers = ["outline", "area", "area-number-circles", "area-numbers", "tour"];

  cardTopOffsets = {};

  currentHighlightId = "";

  areaGeoJson = null;

  skipScroll = false;

  constructor(mapController) {
    this.controller = mapController;
    this.bounds = mapController.getBounds();
  }

  setData() {
    const self = this;
    if (self.controller?.map) {
      if (!self.controller?.map) return;

      self.clear();

      const cards = window.app.classContainer.find(".cards");
      const blocks = window.app.classContainer.find(".block.area");
      if (!cards.exists() && !blocks.exists()) return;

      let json = {};
      if (cards.exists()) {
        self.type = cards.data("tour-type");
        json = getJSONData(`${cards.data("tour-id")}-json`);
      }

      self.currentHighlightId = "";
      if (blocks.exists()) {
        self.type = "area-fix";
        json = getJSONData(`${blocks.eq(0).attr("id")}-json`);

        self.currentHighlightId = blocks.eq(0).data("area-id").trim();
      }

      if (!json?.pins?.features && !json?.areas?.features) return;

      self.areaGeoJson = json?.areas ?? EMPTY_GEOJSON;

      if (!self.controller.map.getSource(self.sourceNameAreas)) {
        self.controller.map.addSource(self.sourceNameAreas, {
          type: "geojson",
          data: json?.areas ?? EMPTY_GEOJSON,
          promoteId: true,
        });
      } else {
        self.controller?.map
          ?.getSource(self.sourceNameAreas)
          ?.setData(json.areas ?? EMPTY_GEOJSON);
      }

      if (!self.controller.map.getSource(self.sourceNamePins)) {
        self.controller.map.addSource(self.sourceNamePins, {
          type: "geojson",
          data: json.pins ?? EMPTY_GEOJSON,
          promoteId: true,
        });
      } else {
        self.controller?.map
          ?.getSource(self.sourceNamePins)
          ?.setData(json.pins ?? EMPTY_GEOJSON);
      }

      let bounds;

      if ((json.pins ?? EMPTY_GEOJSON)?.features?.length) {
        for (
          let i = 0;
          i < (json.pins ?? EMPTY_GEOJSON)?.features?.length;
          i += 1
        ) {
          const coordinates =
            (json.pins ?? EMPTY_GEOJSON)?.features[i]?.geometry?.coordinates ??
            window.WSM.map.center;

          if (self.controller.inBounds(coordinates)) {
            if (!bounds) {
              bounds = new window.mapboxgl.LngLatBounds(
                coordinates,
                coordinates
              );
            } else {
              bounds.extend(coordinates);
            }
          } else {
            const feature = (json.pins ?? EMPTY_GEOJSON)?.features[i];

            // eslint-disable-next-line no-console
            console.warn(
              `Skipped location as it is out of bounds: ID(${feature.properties.id.replace(
                "loc-",
                ""
              )}) - ${self.controller.tHelper.getMultilangValue(
                feature?.properties?.title
              )}`
            );
          }
        }
      }

      if (bounds) {
        self.bounds = bounds;
      } else {
        self.bounds = self.controller.getBounds();
      }
      self.hasDataSet = true;
    }
  }

  scrollIntoView(active) {
    const self = this;
    const tourStop = document.getElementById(active);

    if (tourStop && typeof tourStop?.scrollIntoView !== "undefined") {
      self.skipScroll = true;
      tourStop.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        self.skipScroll = false;
      }, 750);

      self.setHighlight(active);
    }
  }

  setHighlight(active) {
    const self = this;
    self.currentHighlightId = active;

    const card = $(`#${active}`);

    if (card.exists() && card.data("lng") && card.data("lat")) {
      if (self.type === "pin") {
        self.controller.popups.hideAllInScope("pin-");
        self.controller.highlight.tourStop(
          card.attr("id"),
          card.data("number"),
          card.data("lng"),
          card.data("lat"),
          () => {
            self.scrollIntoView(card.attr("id"));
          }
        );
      }

      if (self.type === "area") {
        self.controller.panTo(card.data("lng"), card.data("lat"));

        const hlLayerId = `area-hover-${self.currentHighlightId}`;

        if (self.controller.map.getLayer(hlLayerId)) {
          self.layers
            .filter((layer) => layer.indexOf("area-hover") > -1)
            .forEach((layer) => {
              self.controller.map.setPaintProperty(
                layer,
                "fill-color",
                layer === `area-hover-${self.currentHighlightId}`
                  ? AREA_HOVER_COLOR
                  : AREA_COLOR
              );
            });
        }
      }
    }
  }

  render() {
    const self = this;
    if (self.controller?.map) {
      if (
        !self.controller?.map ||
        !self.hasDataSet ||
        !self.controller.map.getSource(self.sourceNamePins)
      )
        return;

      self.clear();

      let activeLayers = ["tour"];

      // area-fix is used by blocks ...
      if (self.type === "area" || self.type === "area-fix") {
        if (self.type === "area") {
          activeLayers = ["area", "area-number-circles"];
        } else {
          activeLayers = [];
        }

        self.controller.map.addLayer({
          id: "outline",
          type: "line",
          source: self.sourceNameAreas,

          paint: {
            "line-color": "rgb(255, 100, 60)",
            "line-width": 3,
            "line-dasharray": [3, 2],
          },
          filter: ["==", ["get", "type"], "outline"],
        });

        self.controller.map.addLayer({
          id: "area",
          type: "fill",
          source: self.sourceNameAreas,

          paint: {
            "fill-color": "rgb(255, 224, 216)",
          },
          filter: ["==", ["get", "type"], "area"],
        });

        if (self?.areaGeoJson?.features?.length) {
          self.areaGeoJson.features.forEach((feature) => {
            if (feature?.properties?.type === "area") {
              self.layers.push(`area-hover-${feature?.properties?.slug}`);

              self.controller.map.addLayer({
                id: `area-hover-${feature?.properties?.slug}`,
                type: "fill",
                source: self.sourceNameAreas,
                paint: {
                  "fill-color":
                    feature?.properties?.slug === self.currentHighlightId
                      ? AREA_HOVER_COLOR
                      : AREA_COLOR,
                  "fill-color-transition": {
                    duration: 500,
                    delay: 0,
                  },
                },
                filter: ["==", ["get", "slug"], feature?.properties?.slug],
              });
            }
          });
        }

        self.controller.map.addLayer({
          id: "area-number-circles",
          type: "circle",
          source: self.sourceNameAreas,
          paint: {
            "circle-radius": 12,
            "circle-color": "rgb(255, 100, 60)",
          },
          filter: ["==", ["get", "type"], "label"],
        });

        self.controller.map.addLayer({
          id: "area-numbers",
          type: "symbol",
          source: self.sourceNameAreas,
          layout: {
            // "icon-allow-overlap": true,
            // "icon-image": "{marker-symbol}",
            // "icon-size": 0.5,
            // "icon-anchor": "bottom",
            "text-field": ["get", "number"],
            // "text-anchor": "bottom",
            // "text-offset": [0, -0.4],
            "text-font": ["DIN Pro Bold", "Arial Unicode MS Bold"],
            "text-allow-overlap": true,
            "text-optional": true,
            "text-size": 16,
          },
          paint: {
            "text-color": "#fff",
          },

          filter: ["==", ["get", "type"], "label"],
        });
      }

      if (self.type === "pin") {
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#symbol
        self.controller.map.addLayer({
          id: "tour",
          type: "symbol",
          source: self.sourceNamePins,
          layout: {
            "icon-allow-overlap": true,
            "icon-image": "{marker-symbol}",
            "icon-size": 0.5,
            "icon-anchor": "bottom",
            "text-field": ["get", "title"],
            "text-anchor": "bottom",
            "text-font": ["DIN Pro Bold", "Arial Unicode MS Bold"],
            "text-offset": [0, -0.4],
            "text-allow-overlap": true,
            "text-optional": true,
            "text-size": 16,
          },
          paint: {
            "text-color": "#fff",
          },
        });
      }

      if (primaryInput !== "touch") {
        self.events["mouseenter-tour"] = (e) => {
          if (e.eventProcessed) return;
          e.eventProcessed = true;

          if (self.controller.isAnimating) return;
          if (self.controller.map) {
            // Change the cursor style as a UI indicator.
            self.controller.map.getCanvas().style.cursor = "pointer";

            if (e.features?.[0]?.properties?.slug && self.type === "area") {
              self.controller.map.setPaintProperty(
                `area-hover-${e.features?.[0]?.properties?.slug}`,
                "fill-color",
                AREA_HOVER_COLOR
              );
            }
          }
        };

        self.events["mousemove-tour"] = (e) => {
          if (e.eventProcessed) return;
          e.eventProcessed = true;

          if (self.controller.isAnimating) return;
          if (self.controller.map) {
            // Change the cursor style as a UI indicator.
            self.controller.map.getCanvas().style.cursor = "pointer";
          }
        };

        self.events["mouseleave-tour"] = (e) => {
          if (e.eventProcessed) return;
          e.eventProcessed = true;

          if (self.controller.map) {
            self.controller.map.getCanvas().style.cursor = "";

            if (self.type === "area") {
              self.layers
                .filter(
                  (layer) =>
                    layer.indexOf("area-hover") > -1 &&
                    layer !== `area-hover-${self.currentHighlightId}`
                )
                .forEach((layer) => {
                  self.controller.map.setPaintProperty(
                    layer,
                    "fill-color",
                    AREA_COLOR
                  );
                });
            }
          }
        };

        activeLayers.forEach((layer) => {
          self.controller.map.on(
            "mouseenter",
            layer,
            self.events["mouseenter-tour"]
          );
          self.controller.map.on(
            "mousemove",
            layer,
            self.events["mousemove-tour"]
          );
          self.controller.map.on(
            "mouseleave",
            layer,
            self.events["mouseleave-tour"]
          );
        });
      }

      self.events["click-tour"] = (e) => {
        if (e.eventProcessed) return;
        e.eventProcessed = true;

        const active =
          e.features?.[0]?.properties?.slug ?? e.features?.[0]?.properties?.id;
        if (active) {
          self.scrollIntoView(active);
        }
      };

      activeLayers.forEach((layer) => {
        self.controller.map.on("click", layer, self.events["click-tour"]);
      });
    }
    self.show();
  }

  onScrollDebounced = throttle(() => {
    const self = this;
    if (self.skipScroll) return;

    if (Object.keys(self.cardTopOffsets).length) {
      const active = Object.keys(self.cardTopOffsets).reduce((carry, key) => {
        if (carry === null || window.app.sT + window.app.wH * 0.5 >= key)
          return self.cardTopOffsets[key];

        return carry;
      }, null);

      if (active !== self.currentHighlightId) {
        self.setHighlight(active);
      }
    }
  }, 500);

  onScroll() {
    if (this.type !== "area-fix") this.onScrollDebounced();
  }

  onResize() {
    const self = this;

    if (self.type === "area-fix") return;

    self.currentHighlightId = "";

    $(".card.observe").each((index, element) => {
      const card = $(element);

      self.cardTopOffsets = {
        ...self.cardTopOffsets,
        [card.offset().top]: card.attr("id"),
      };
    });
  }

  setFilter(/* filter */) {}

  fitToBounds() {
    const self = this;
    if (self.controller?.map) {
      self.controller.fitToBounds(self.bounds, {
        maxZoom: self.controller.MAX_BOUNDS_ZOOM,
        padding: self.controller.getBoundsPadding(),
      });
    }
  }

  hide() {
    const self = this;
    if (!self.isVisible) return;

    self.controller.toggleLayersVisibility(self.layers, "none");

    self.isVisible = false;
  }

  show() {
    const self = this;
    if (self.isVisible) return;

    self.controller.toggleLayersVisibility(self.layers, "visible");

    self.isVisible = true;
  }

  clear() {
    const self = this;
    if (self.controller?.map) {
      self.isVisible = false;
      self.isDataSet = true;

      self.controller.removeLayers(self.layers);

      if (Object.keys(self.events).length) {
        Object.keys(self.events).forEach((key) => {
          if (self.controller?.map) {
            const params = key.split("-");
            if (params.length > 1) {
              self.controller.map.off(params[0], params[1], self.events[key]);
            } else {
              self.controller.map.off(key, self.events[key]);
            }
          }
        });
        self.events = {};
      }
    }
  }
}
